import { createSelector } from "reselect";

import queryString from "query-string";
import axios from "helpers/interceptor";
import createReducer from "helpers/createReducer";
import URLS from "./urls";

const GET_REPORTS = "reports/report/GET_REPORTS";
const GET_REPORTS_FULFILLED = "reports/report/GET_REPORTS_FULFILLED";
const GET_REPORT = "reports/report/GET_REPORT";
const GET_REPORT_FULFILLED = "reports/report/GET_REPORT_FULFILLED";
const GET_REPORT_DATA = "reports/report/GET_REPORT_DATA";
const GET_REPORT_DATA_FULFILLED = "reports/report/GET_REPORT_DATA_FULFILLED";
const RESET_REPORT_DATA = "reports/report/RESET_REPORT_DATA";
const GET_DOWNLOAD_REPORT = "reports/report/GET_DOWNLOAD_REPORT";
const GET_CORRECTION_MESSAGE = "reports/report/GET_CORRECTION_MESSAGE";

function getQueryParams(params) {
  let parsedParams;
  let queryParams;

  if (params.reportDetail) {
    parsedParams = JSON.parse(params.reportDetail);
  }

  switch (params.code) {
    case "MANUAL-CORRECTION-SUMMARY":
      queryParams = `?company_id='${params.company_id}'
      &end_date='${params.end_date}'
      &start_date='${params.start_date}'
      &field=${params.field || "Count_of_Correction"}
      &order=${params.order || "DESC"}
      &department_id=${params.department_id || ""}&team_id=${params.team_id ||
        ""}`;
      break;

    case "CORRECTION-REQUEST-REPORT":
      queryParams = `?company_id='${params.company_id}'
      &end_date='${params.end_date}'
      &start_date='${params.start_date}'
      &field=${params.field || "Correction_Requests_By_Employee"}
      &order=${params.order || "DESC"}
      &department_id=${params.department_id || ""}&team_id=${params.team_id ||
        ""}`;
      break;

    case "MANUAL-CORRECTION-DETAIL":
      queryParams = `?employee_id='${params.detailId}'
      &end_date='${params.end_date}'
      &start_date='${params.start_date}'
      &field=${params.field || '"timecards_dailytimecard"."date"'}
      &order=${params.order || "DESC"}`;
      break;

    case "EMPLOYEE-ACTIVITY-DETAILS-BY-DATE":
      queryParams = `?employee_id='${params.detailId}'
      &end_datetime='${params.end_date}'
      &start_datetime='${params.start_date}'
      &field=${params.field || '"activity_activitysplit"."start_datetime"'}
      &order=${params.order || "DESC"}`;
      break;

    case "MEAL-VIOLATION":
      queryParams = `?company_id='${params.company_id}'
      &end_date='${params.end_date}'
      &start_date='${params.start_date}'
      &field=${params.field || '"accounts_user"."first_name"'}
      &order=${params.order || "ASC"}
      &department_id=${params.department_id || ""}&team_id=${params.team_id ||
        ""}`;
      break;

    case "MEAL-VIOLATION-DETAIL":
      queryParams = `?employee_id='${params.detailId}'
      &end_date='${params.end_date}'
      &start_date='${params.start_date}'
      &field=${params.field || '"activity_start_datetime"'}
      &order=${params.order || "DESC"}`;
      break;

    case "OVERTIME-SUMMARY":
      queryParams = `?company_id='${params.company_id}'
      &end_date='${params.end_date}'
      &start_date='${params.start_date}'
      &field=${params.field || '"accounts_user"."first_name"'}
      &order=${params.order || "ASC"}
      &department_id=${params.department_id || ""}&team_id=${params.team_id ||
        ""}`;
      break;

    case "OVERTIME-DETAIL":
      queryParams = `?employee_id='${params.detailId}'
      &end_date='${params.end_date}'
      &start_date='${params.start_date}'
      &field=${params.field || '"timecards_dailytimecard"."date"'}
      &order=${params.order || "DESC"}`;
      break;

    case "TIMECARD-DETAIL":
      queryParams = `?employee_id='${params.employee_id}'
      &end_date='${params.end_date}'
      &start_date='${params.start_date}'
      &field=${params.field || '"activity_activitysplit"."date"'}
      &order=${params.order || "DESC"}`;
      break;

    case "JOB-SUMMARY":
      queryParams = `?job_ids=${params.job_ids}
      &end_date='${params.end_date}'
      &start_date='${params.start_date}'
      &field=${params.field || '"accounts_user"."first_name"'}
      &order=${params.order || "ASC"}`;
      break;

    case "JOB-DETAIL":
      queryParams = `?job_id=${parsedParams.job_ids}
      &employee_id='${params.detailId}'
      &end_date='${parsedParams.end_date}'
      &start_date='${parsedParams.start_date}'
      &field=${params.field || "date"}
      &order=${params.order || "DESC"}`;
      break;

    case "EMPLOYEE-WISE-DAILY-TIMECARDS": {
      const { code, ...rest } = params;
      queryParams = `?${queryString.stringify(rest)}&field=${params.field ||
        '"accounts_user"."first_name"'}
      &order=${params.order || "ASC"}`;
      break;
    }

    case "GEO-FENCE-BREACH-SUMMARY":
      queryParams = `?job_ids=${params.job_ids}
      &end_datetime='${params.end_date}'
      &start_datetime='${params.start_date}'
      &end_date='${params.end_date}'
      &start_date='${params.start_date}'
      &field=${params.field || '"accounts_user"."first_name"'}
      &order=${params.order || "ASC"}`;
      break;

    case "GEO-FENCE-BREACH-DETAIL":
      queryParams = `?job_ids=${params.job_ids}
      &employee_id='${params.detailId}'
      &end_datetime='${parsedParams.end_date}'
      &end_date='${parsedParams.end_date}'
      &start_datetime='${parsedParams.start_date}'
      &start_date='${parsedParams.start_date}'
      &field=${params.field || '"accounts_user"."first_name"'}
      &order=${params.order || "ASC"}`;
      break;

    case "JOB-TASK-TIME-ALLOCATION": {
      const { code, ...rest } = params;
      queryParams = `?${queryString.stringify(rest)}&field=${"first_name"}
      &order=${params.order || "DESC"}`;
      break;
    }

    case "MEAL-FLAG-DETAIL":
      queryParams = `?company_id='${params.company_id}'
      &end_date='${params.end_date}'
      &start_date='${params.start_date}'
      &field=${params.field || '"activity_start_datetime"'}
      &order=${params.order || "DESC"}
      &department_id=${params.department_id || ""}&team_id=${params.team_id ||
        ""}&employee_id=${params.employee_id || ""}`;
      break;
    
    case "DETAILS-CORRECTION-REQUEST-REPORT":
      queryParams = `?employee_id='${params.detailId}'
      &end_datetime='${params.end_date}'
      &start_datetime='${params.start_date}'
      &field=${params.field || '"activity_activitysplit"."start_datetime"'}
      &order=${params.order || "DESC"}`;
      break;

    case "GENERAL-AUDIT-REPORT":
      queryParams = `?company_id='${params.company_id}'
      &end_date='${params.end_date}'
      &start_date='${params.start_date}'
      &field=${params.field || 'session_id'}
      &order=${params.order || "DESC"}
      &department_id=${params.department_id || ""}&team_id=${params.team_id ||
        ""}&changed_by=${params.changed_by ||
        ""}&employee_ids=${params.employee_ids || ""}`;
      break;

    case "EMPLOYEE-PROFICIENCY-REPORT":
      queryParams = `?company_id='${params.company_id}'
      &end_date='${params.end_date}'
      &start_date='${params.start_date}'
      &field=${params.field || 'employee_name'}
      &order=${params.order || "ASC"}
      &department_id=${params.department_id || ""}&team_id=${params.team_id ||
        ""}`;
      break;

    default:
      break;
  }
  return queryParams;
}

export function getCorrectionMessage(params){
  return {
    type: GET_CORRECTION_MESSAGE,
    payload: axios.get(URLS.CORRECTION_MESSAGE, { params })
  }
}

export function getReports(params) {
  return {
    type: GET_REPORTS,
    payload: axios.get(URLS.REPORT, { params }),
  };
}

export function getReport(params) {
  return {
    type: GET_REPORT,
    payload: axios.get(`${URLS.REPORT}${params.id}/`),
  };
}

export function getReportData(params) {
  return {
    type: GET_REPORT_DATA,
    payload: axios.get(
      `${URLS.REPORT}${params.id}/${URLS.DETAIL}${getQueryParams(params)}`
    ),
  };
}

export function downloadReportData(parameters) {
  const { id, ...params } = parameters;
  return {
    type: GET_DOWNLOAD_REPORT,
    payload: axios.get(
      `${URLS.REPORT}${id}/${URLS.DOWNLOAD}${getQueryParams(params)}`,
      { responseType: "arraybuffer" }
    ),
  };
}

export function resetReportData() {
  return {
    type: RESET_REPORT_DATA,
  };
}

const defaultState = {
  list: { results: [] },
  detail: { parent_report: [] },
  data: [],
  correction_message:[],
};

function GET_CORRECTION_MESSAGE_FULFILLED_REDUCER(state, action){
  return Object.assign({}, state, {
    correction_message: action.payload,
  });
}

function GET_REPORTS_FULFILLED_REDUCER(state, action) {
  return Object.assign({}, state, {
    list: action.payload,
  });
}

function GET_REPORT_FULFILLED_REDUCER(state, action) {
  return Object.assign({}, state, {
    detail: action.payload,
  });
}

function GET_REPORT_DATA_FULFILLED_REDUCER(state, action) {
  return Object.assign({}, state, {
    data: JSON.parse(action.payload),
  });
}

function RESET_REPORT_DATA_REDUCER(state, action) {
  return Object.assign({}, state, { data: [] });
}

const handlers = {
  [GET_REPORTS_FULFILLED]: GET_REPORTS_FULFILLED_REDUCER,
  [GET_REPORT_FULFILLED]: GET_REPORT_FULFILLED_REDUCER,
  [GET_REPORT_DATA_FULFILLED]: GET_REPORT_DATA_FULFILLED_REDUCER,
  [RESET_REPORT_DATA]: RESET_REPORT_DATA_REDUCER,
  [GET_CORRECTION_MESSAGE] : GET_CORRECTION_MESSAGE_FULFILLED_REDUCER,
};

const reportSelector = (state) => state.reports.report;

export const reportList = createSelector(
  reportSelector,
  (instance) => instance.list
);

export const mcsReportDetail = createSelector(
  reportList,
  (instance) =>
    instance.results.filter(
      (item) => item.code === "MANUAL-CORRECTION-SUMMARY"
    )[0]
);

export const mvReportDetail = createSelector(
  reportList,
  (instance) =>
    instance.results.filter((item) => item.code === "MEAL-VIOLATION")[0]
);

export const jsReportDetail = createSelector(
  reportList,
  (instance) =>
    instance.results.filter((item) => item.code === "JOB-SUMMARY")[0]
);

export const gbReportDetail = createSelector(
  reportList,
  (instance) =>
    instance.results.filter(
      (item) => item.code === "GEO-FENCE-BREACH-SUMMARY"
    )[0]
);

export const osReportDetail = createSelector(
  reportList,
  (instance) =>
    instance.results.filter((item) => item.code === "OVERTIME-SUMMARY")[0]
);

export const reportDetail = createSelector(
  reportSelector,
  (instance) => instance.detail
);

export const reportData = createSelector(reportSelector, (instance) =>
  instance.data.map((item, index) => ({ ...item, index }))
);

export default createReducer(defaultState, handlers);
