import { lazy } from "react";
import HomePage from "pages/generics/Home";
import NotificationList from "notification/pages/notificationList";
import VendorListPageWrapper from "pages/vendors/VendorListPageWrapper";
import PayrollListWrapper from "pages/payrolls/PayrollListWrapper";
import KnowledgeBaseWrapper from "pages/knowledgeBases/KnowledgeBaseWrapper";
import QualityAssuranceListPageWrapper from "pages/qualityAssurance/components/QualityAssuranceListPageWrapper";
import SmartConnectListPageWrapper from 'pages/smartConnect/components/SmartConnectListPageWrapper'
const ActivationPage = lazy(() => import("accounts/pages/activation"));
const ForgotPasswordPage = lazy(() => import("accounts/pages/forgotPassword"));
const SetPasswordPage = lazy(() => import("accounts/pages/setPassword"));
const LoginPage = lazy(() => import("accounts/pages/signIn"));
const RegisterPage = lazy(() => import("accounts/pages/register"));
const ResetPasswordPage = lazy(() => import("accounts/pages/resetPassword"));
const AccountSuspensionPage = lazy(() =>
  import("accounts/pages/accountSuspension")
);
const AccountCancellationPage = lazy(() =>
  import("accounts/pages/accountCancellation")
);
const ChangeExpPasswordPage = lazy(() =>
  import("accounts/pages/accountChangeExpPass")
);
const VerifyOtpPage = lazy(() => import("accounts/pages/verifyOtp"));
const TermsConditionPage = lazy(() =>
  import("accounts/pages/termsConditionPage")
);
const ChangePassword = lazy(() => import("pages/accounts/ChangePassword"));
const ProfilePage = lazy(() => import("pages/accounts/Profile"));

//////// survey components/////////
const SurveyListPage = lazy(() => import("surveys/pages/list"));
const SurveyFormPage = lazy(() => import("surveys/pages/surveyForm"));
const SurveyFormQuestionsPage = lazy(() =>
  import("surveys/pages/surveyFormQuestions")
);
const SurveyFormOutcomesPage = lazy(() =>
  import("surveys/pages/surveyFormOutcomes")
);
const SurveyFormEditPage = lazy(() => import("surveys/pages/surveyFormEdit"));
const SurveyTemplateListPage = lazy(() =>
  import("surveys/pages/surveyTemplateList")
);

//////// employee components/////////
const EmployeeBulkUpload = lazy(() => import("pages/employees/BulkUpload"));
const KioskEmployeesAuditListPage = lazy(() =>
  import("pages/employees/KioskEmployeesAuditList")
);
const EmployeeForm = lazy(() => import("employees/pages/form"));
const ListPage = lazy(() => import("employees/pages/list"));
const JobsListPage = lazy(() => import("employees/pages/jobsList"));

/////// QUALITY ASSUARANCE ///////

const QualityAssuranceListPage = lazy(() =>
  import("pages/qualityAssurance/index")
);

////////SMART CONNECT/////////////

const SmartConnectListPage = lazy(() => import("pages/smartConnect/index"));

/////// Vendor components/////////

const InvoiceDetailFormPage = lazy(() =>
  import("payments/invoices/pages/invoiceDetail")
);
const AccountFormPage = lazy(() => import("company/pages/accountForm"));
const CompanyBillorPlanPage = lazy(() => import("payments/bills/pages/plan"));
const PaymentMethodPage = lazy(() =>
  import("payments/paymentMethods/pages/paymentMethod")
);
const AddCardPage = lazy(() => import("payments/paymentMethods/pages/addCard"));
const BasicFormPage = lazy(() => import("pages/vendors/BasicSetting"));
const VendorFormPage = lazy(() => import("pages/vendors/Form"));
const VendorListPage = lazy(() => import("pages/vendors/List"));
const VendorInvoicePage = lazy(() => import("payments/invoices/pages/list"));
const NotificationSettingListPage = lazy(() =>
  import("pages/vendors/NotificationSettingList")
);
const RoleListPage = lazy(() => import("pages/vendors/RoleList"));
const RoleFormPage = lazy(() => import("pages/vendors/RoleForm"));
const SubCompanyListPage = lazy(() => import("pages/vendors/SubCompanyList"));
const SystemSettingListPage = lazy(() =>
  import("pages/vendors/SystemSettingList")
);
const AdjustmentTypesListPage = lazy(() =>
  import("pages/vendors/AdjustmentTypesList")
);
const MealAdjustmentsListPage = lazy(() =>
  import("pages/vendors/MealAdjustments")
);
const IncompleteSignUpListPage = lazy(() =>
  import("pages/vendors/IncompleteSignUpList")
);
const VendorDepartmentListPage = lazy(() =>
  import("pages/vendors/DepartmentList")
);
const VendorSubCompanyFormPage = lazy(() =>
  import("pages/vendors/SubCompanyForm")
);
const VendorSubCompanyUserFormPage = lazy(() =>
  import("pages/vendors/SubCompanyUserForm")
);
const PluginPage = lazy(() => import("pages/vendors/Plugin"));
const PluginSettingsPage = lazy(() => import("pages/vendors/PluginSettings"));
const PluginSettingsSetupListPage = lazy(() =>
  import("pages/vendors/PluginSettingsSetupList")
);
const QBDesktopPage = lazy(() => import("pages/vendors/QBDesktop"));
const ADPDesktopPage = lazy(() => import("pages/vendors/ADPDesktop"));
const SystemFormsPage = lazy(() => import("forms/pages/systemForms"));
const MealAdjustmentsDetails = lazy(() =>
  import("pages/vendors/MealAdjustmentsDetails")
);
const MealAdjustmentsSettings = lazy(() =>
  import("pages/vendors/MealAdjustmentsSettings")
);
const MealAdjustmentsEmpSelection = lazy(() =>
  import("pages/vendors/MealAdjustmentsEmpSelection")
);

///////////payroll components/////////////
const PayrollFormPage = lazy(() => import("pages/payrolls/Form"));
const PayrollListPage = lazy(() => import("pages/payrolls/List"));
const PayrollSettingListPage = lazy(() => import("pages/payrolls/Setting"));
const PayrollSettingFormPage = lazy(() => import("pages/payrolls/SettingForm"));

///////////timecards components////////////
const CustomDailyTimecardListPage = lazy(() =>
  import("pages/timecards/CustomDailyTimecardList")
);
const CustomDailyTimecardListPageV2 = lazy(() =>
  import("pages/timecards/CustomDailyTimecardListV2")
);
const DeviatedDailyTimecardListPage = lazy(() =>
  import("pages/timecards/DeviatedDailyTimecardList")
);
const CustomCreateTimeCardPage = lazy(() =>
  import("pages/timecards/CustomCreateTimecard")
);
const CustomTimecardListPage = lazy(() =>
  import("pages/timecards/CustomTimecardList")
);

///////////activity components///////////////
const CustomActivitySplitListPage = lazy(() =>
  import("pages/activities/CustomActivitySplitList")
);
const CustomActivitySplitForm = lazy(() =>
  import("pages/activities/CustomActivitySplitForm")
);
const ManualSessionPage = lazy(() => import("pages/activities/ManualSession"));
const TrackCurrentListPage = lazy(() =>
  import("pages/activities/TrackCurrentList")
);
const TrackHistoricListPage = lazy(() =>
  import("pages/activities/TrackHistoricList")
);
const ActivityHistoricSplitListPage = lazy(() =>
  import("pages/activities/ActivityHistoricSplitList")
);

////////////report components////////////////
const ReportDashboardPage = lazy(() => import("pages/reports/Dashboard"));
const ReportDetailPage = lazy(() => import("pages/reports/Detail"));
const ReportDataDetailPage = lazy(() => import("pages/reports/DataDetail"));

///////////geofence components///////////
const GeoFenceFormPage = lazy(() => import("pages/geoFences/Form"));
const GeoFenceListPage = lazy(() => import("pages/geoFences/List"));

//////////////Job schedule components////////////
const JobFormPage = lazy(() => import("pages/jobs/Form"));
const EditJobFormPage = lazy(() => import("pages/jobs/FormEditJob"));
const JobListPage = lazy(() => import("pages/jobs/List"));
const JobTaskMappingListPage = lazy(() =>
  import("pages/jobs/JobTaskMappingList")
);
const JobGeofencePage = lazy(() => import("pages/jobs/jobGeofence"));
const JobTaskEditPage = lazy(() => import("pages/jobs/JobTaskEdit"));
const JobReportsPage = lazy(() => import("pages/jobs/jobReports"));
const JobEmployeeEditPage = lazy(() => import("pages/jobs/jobEmployeeEdit"));
const JobBudgetPage = lazy(() => import("pages/jobs/jobBudget"));
const TaskBulkUploadPage = lazy(() => import("pages/jobs/TaskBulkUpload"));
const JobBulkUploadPage = lazy(() => import("pages/jobs/JobBulkUpload"));
const ManageJobsPage = lazy(() => import("pages/jobs/ManageJobsPage"));
const JobDocumentsPage = lazy(() => import("pages/jobs/JobDocumentsPage"));

////////////////customer components//////////////
const CustomerBulkUploadPage = lazy(() => import("pages/customers/BulkUpload"));
const CustomerListPage = lazy(() => import("pages/customers/List"));
const CustomerFormPage = lazy(() => import("pages/customers/Form"));

//////////////////knowledgebase components/////////////////
const KnowledgeBaseListPage = lazy(() => import("pages/knowledgeBases/List"));
const KnowledgeBaseFormPage = lazy(() => import("pages/knowledgeBases/Form"));

////////////////billing components///////////////////
const BillListWrapper = lazy(() => import("payments/bills/pages/BillWrapper"));
const AllBillsPage = lazy(() => import("payments/bills/pages/list"));
const BillFormPage = lazy(() => import("payments/bills/pages/form"));

///////////////scheduling components////////////////////
const SchedulingDashboardPage = lazy(() =>
  import("pages/scheduling/Dashboard")
);
const ShiftManagementPage = lazy(() =>
  import("pages/scheduling/ShiftManagement")
);

////////////////pto components////////////////////
const PtoPolicyPage = lazy(() => import("pages/pto/PtoPolicyPage"));
const TimeOffListPage = lazy(() => import("pages/pto/TimeOffListPage"));
const BulkUploadPage = lazy(() => import("pages/pto/BulkUpload"));
const EditPtoPolicyPage = lazy(() => import("pages/pto/EditPtoPolicyPage"));

//////////////Notification components/////////////////

//////////////communication center components///////////////
const DocuentSetupPage = lazy(() =>
  import("communicationCenter/pages/documentSetup")
);
// const DocumentCompletionList = lazy(() => import('communicationCenter/pages/documentCompletionList'));

////////////////learning center components/////////////////
const LearningCenterSetupPage = lazy(() =>
  import("learningCenter/pages/learningCenterSetup")
);
const CreateAssessmentForm = lazy(() =>
  import("learningCenter/pages/createAssessmentForm")
);
const DocumentCompletionList = lazy(() =>
  import("learningCenter/pages/documentCompletionList")
);
const NotificationListPage = lazy(() =>
  import("notification/pages/notificationList")
);

////////////////form components///////////////////
const FieldFormsPage = lazy(() => import("forms/pages/fieldForms"));
const CreateFieldForm = lazy(() => import("forms/pages/createFieldForm"));
const FormResponseList = lazy(() => import("forms/pages/formResponseList"));

////////////////applicant components///////////////
// const ListPage = lazy(() => import('applicants/pages/list'));
const AddApplicant = lazy(() => import("applicants/pages/addApplicant"));
const ApplicantListPage = lazy(() => import("applicants/pages/list"));

///////////////generic components///////////////
const DashboardPage = lazy(() => import("pages/generics/Dashboard"));
const DashboardLandingPage = lazy(() =>
  import("pages/generics/DashboardLanding")
);
const AdminDashboardPage = lazy(() => import("pages/generics/AdminDashboard"));

const BusinessAdminForm = lazy(() =>
  import("pages/businessAdmins/BasicSetting")
);

const routes = [
  ///////////////////generic routes//////////////////

  {
    path: "/dashboard",
    component: DashboardPage,
    private: true,
    exact: true,
    name: "dashboard",
  },
  {
    path: `/dashboard-landing`,
    component: DashboardLandingPage,
    exact: true,
    private: true,
    name: "dashboard_landing",
  },
  {
    path: `/dashboard-admin`,
    component: AdminDashboardPage,
    exact: true,
    private: true,
    name: "dashboard_admin",
  },
  {
    path: `/`,
    component: HomePage,
    exact: true,
  },

  ///////////accounts routes////////////

  {
    path: "/accounts/login",
    component: LoginPage,
    unauth: true,
    exact: true,
    name: "accounts.login",
  },
  {
    path: "/accounts/register",
    component: RegisterPage,
    unauth: true,
    exact: true,
    name: "accounts.register",
  },
  {
    path: "/accounts/suspension",
    component: AccountSuspensionPage,
    exact: true,
    private: true,
    name: "accounts.suspension",
  },
  {
    path: "/accounts/cancellation",
    component: AccountCancellationPage,
    exact: true,
    private: true,
    name: "accounts.cancellation",
  },

  {
    path: "/accounts/forgot-password",
    component: ForgotPasswordPage,
    unauth: true,
    exact: true,
    name: "accounts.forgot_password",
  },
  {
    path: "/accounts/change-password",
    component: ChangePassword,
    exact: true,
    name: "accounts.change_password",
    private: true,
  },
  {
    path: "/accounts/activate-user",
    component: ActivationPage,
    name: "accounts.activate_user",
    exact: true,
    private: true,
  },
  {
    path: "/accounts/reset-password",
    component: ResetPasswordPage,
    unauth: true,
    exact: true,
    name: "accounts.reset_password",
  },
  {
    path: "/accounts/profile",
    component: ProfilePage,
    exact: true,
    name: "accounts.profile",
    private: true,
  },
  {
    path: "/accounts/set-password",
    component: SetPasswordPage,
    unauth: true,
    exact: true,
    name: "accounts.set_password",
  },
  {
    path: "/accounts/change-expired-password",
    component: ChangeExpPasswordPage,
    exact: true,
    name: "accounts.change-expired-password",
    private: true,
  },
  {
    path: "/accounts/login-otp",
    component: VerifyOtpPage,
    unauth: true,
    exact: true,
    name: "accounts.logotp",
  },
  {
    path: "/accounts/terms&condition",
    component: TermsConditionPage,
    exact: true,
    private: true,
    name: "accounts.terms&condn",
  },

  //////// survey routes/////////
  {
    path: "/surveys/list",
    component: SurveyTemplateListPage,
    exact: true,
    private: true,
    name: "surveys.list",
  },
  {
    path: "/surveys/add",
    component: SurveyFormPage,
    exact: true,
    private: true,
    name: "surveys.add",
  },
  {
    path: "/surveys/:id/questions",
    component: SurveyFormQuestionsPage,
    exact: true,
    private: true,
    name: "surveys.questions",
  },
  {
    path: "/surveys/:id/outcomes",
    component: SurveyFormOutcomesPage,
    exact: true,
    private: true,
    name: "surveys.outcomes",
  },
  {
    path: "/surveys/:id/edit",
    component: SurveyFormEditPage,
    exact: true,
    private: true,
    name: "surveys.edit",
  },
  {
    path: "/surveys/dashboard",
    component: SurveyListPage,
    exact: true,
    private: true,
    name: "surveys.dashboard",
  },

  //////// employee routes/////////

  {
    path: "/employee/list",
    component: ListPage,
    exact: true,
    private: true,
    name: "employee.list",
  },
  {
    path: "/employee/add",
    component: EmployeeForm,
    exact: true,
    private: true,
    name: "employee.add",
  },
  {
    path: "/employee/:id/edit/:tab",
    component: EmployeeForm,
    exact: true,
    private: true,
    name: "employee.edit",
  },
  {
    path: "/employee/kiosk-audit/list",
    component: KioskEmployeesAuditListPage,
    exact: true,
    private: true,
    name: "employee.kiosk_audit_list",
  },
  {
    path: "/employee/bulk-upload",
    component: EmployeeBulkUpload,
    exact: true,
    private: true,
    name: "employee.bulkupload",
  },
  {
    path: "/employee/:id/jobs/list",
    component: JobsListPage,
    exact: true,
    private: true,
    name: "employee.bulkupload",
  },

  //////////////Vendor Routes//////////////
  {
    path: "/vendor/list/",
    component: VendorListPageWrapper,
    exact: true,
    private: true,
    name: "vendor.list.all",
  },
  {
    path: "/vendor/list/all",
    component: VendorListPage,
    exact: true,
    private: true,
    name: "vendor.list.all",
  },
  {
    path: "/vendor/invoice",
    private: true,
    component: VendorInvoicePage,
    exact: true,
    name: "vendor.invoice",
  },
  {
    path: "/vendor/invoice/:id/invoice-detail",
    component: InvoiceDetailFormPage,
    exact: true,
    private: true,
    name: "vendor.invoice.detail",
  },
  {
    path: "/vendor/list/incomplete-signup",
    component: IncompleteSignUpListPage,
    exact: true,
    private: true,
    name: "vendor.list.incomplete_signup",
  },
  {
    path: "/vendor/profile",
    component: VendorFormPage,
    exact: true,
    private: true,
    name: "vendor.profile",
  },
  {
    path: "/vendor/system-setting",
    component: SystemSettingListPage,
    exact: true,
    private: true,
    name: "vendor.system_setting",
  },
  {
    path: "/vendor/adjustment-types",
    component: AdjustmentTypesListPage,
    exact: true,
    private: true,
    name: "dashboard",
  },
  {
    path: "/vendor/meal-adjustments/:id/mealDetails",
    component: MealAdjustmentsDetails,
    exact: true,
    private: true,
    name: "dashboard",
  },
  {
    path: "/vendor/meal-adjustments/mealDetails",
    component: MealAdjustmentsDetails,
    exact: true,
    private: true,
    name: "dashboard",
  },
  {
    path: "/vendor/meal-adjustments/:id/mealSettings",
    component: MealAdjustmentsSettings,
    exact: true,
    private: true,
    name: "dashboard",
  },
  {
    path: "/vendor/meal-adjustments/:id/employeeSelection",
    component: MealAdjustmentsEmpSelection,
    exact: true,
    private: true,
    name: "dashboard",
  },
  {
    path: "/vendor/meal-adjustments",
    component: MealAdjustmentsListPage,
    exact: true,
    private: true,
    name: "dashboard",
  },
  {
    path: "/vendor/notification-setting",
    component: NotificationSettingListPage,
    exact: true,
    private: true,
    name: "vendor.notification_setting",
  },
  {
    path: "/vendor/basic-setting",
    component: BasicFormPage,
    exact: true,
    private: true,
    name: "vendor.basic_setting",
  },
  {
    path: "/vendor/role-setting",
    component: RoleListPage,
    exact: true,
    private: true,
    name: "vendor.role_setting",
  },
  {
    path: "/vendor/role-setting/add",
    component: RoleFormPage,
    exact: true,
    private: true,
    name: "vendor.role_setting.add",
  },
  {
    path: "/vendor/role-setting/:id/edit",
    component: RoleFormPage,
    exact: true,
    private: true,
    name: "vendor.role_setting.edit",
  },
  {
    path: "/vendor/sub-company",
    component: SubCompanyListPage,
    exact: true,
    private: true,
    name: "vendor.company_setting",
  },

  {
    path: "/vendor/department",
    component: VendorDepartmentListPage,
    exact: true,
    private: true,
    name: "vendor.department_setting",
  },
  {
    path: "/vendor/sub-company/add",
    component: VendorSubCompanyFormPage,
    exact: true,
    private: true,
    name: "vendor.sub_company.add",
  },
  {
    path: "/vendor/sub-company/:id/user",
    component: VendorSubCompanyUserFormPage,
    exact: true,
    private: true,
    name: "vendor.sub_company_user.add",
  },
  {
    path: "/vendor/sub-company/:id/edit",
    component: VendorSubCompanyFormPage,
    exact: true,
    private: true,
    name: "vendor.sub_company.edit",
  },

  {
    path: "/vendor/plugin",
    component: PluginPage,
    exact: true,
    private: true,
    name: "vendor.plugin",
  },
  {
    path: "/vendor/plugin/quickbooks-desktop",
    component: QBDesktopPage,
    exact: true,
    private: true,
    name: "vendor.plugin.quickbooks-desktop",
  },
  {
    path: "/vendor/plugin/adp-desktop",
    component: ADPDesktopPage,
    exact: true,
    private: true,
    name: "vendor.plugin-settings",
  },
  {
    path: "/vendor/plugin/settings/Quickbooks/:tab",
    component: PluginSettingsPage,
    exact: true,
    private: true,
    name: "vendor.plugin-settings",
  },
  {
    path: "/vendor/plugin/install/Quickbooks",
    component: PluginSettingsSetupListPage,
    exact: true,
    private: true,
    name: "vendor.plugin-settings-setup",
  },
  {
    path: "/vendor/plugin/uninstall/Quickbooks",
    component: PluginSettingsSetupListPage,
    exact: true,
    private: true,
    name: "vendor.plugin-settings-setup",
  },
  {
    path: "/vendor/plan",
    component: CompanyBillorPlanPage,
    private: true,
    exact: true,
    name: "vendor.plan",
  },
  {
    path: "/vendor/payment-method",
    component: PaymentMethodPage,
    exact: true,
    private: true,
    name: "vendor.payment-method",
  },
  {
    path: "/vendor/add-card",
    component: AddCardPage,
    exact: true,
    private: true,
    name: "vendor.add-card",
  },
  {
    path: "/vendor/:id/:tab/",
    component: AccountFormPage,
    exact: true,
    private: true,
    name: "vendor.account",
  },
  {
    path: "/vendor/plugin/install/Adp",
    component: PluginSettingsSetupListPage,
    exact: true,
    private: true,
    name: "vendor.plugin-settings-setup",
  },
  {
    path: "/vendor/system-forms",
    component: SystemFormsPage,
    exact: true,
    private: true,
    name: "forms.systemforms",
  },

  /////////////payroll routes/////////////

  {
    path: "/payroll",
    component: PayrollListWrapper,
    exact: true,
    private: true,
  },
  {
    path: "/payroll/list",
    component: PayrollListPage,
    exact: true,
    private: true,
    name: "payroll.list",
  },
  {
    path: "/payroll/add",
    component: PayrollFormPage,
    exact: true,
    private: true,
    name: "payroll.add",
  },
  {
    path: "/payroll/:id/detail",
    component: PayrollFormPage,
    exact: true,
    private: true,
    name: "payroll.detail",
  },
  {
    path: "/payroll/setting/list",
    component: PayrollSettingListPage,
    exact: true,
    private: true,
    name: "payroll.setting.list",
  },
  {
    path: "/payroll/setting/add",
    component: PayrollSettingFormPage,
    exact: true,
    private: true,
    name: "payroll.setting.add",
  },
  {
    path: "/payroll/setting/:id/edit",
    component: PayrollSettingFormPage,
    exact: true,
    private: true,
    name: "payroll.setting.edit",
  },

  //////////////timecards routes////////////////

  {
    path: `/timecard/historic`,
    component: CustomDailyTimecardListPageV2,
    exact: true,
    private: true,
    name: "timecards.timecard.historic_timecard.list",
  },
  {
    path: `/timecard/deviated-daily`,
    component: DeviatedDailyTimecardListPage,
    exact: true,
    private: true,
    name: "timecards.timecard.deviated_daily_timecard.list",
  },
  {
    path: `/timecard/custom/list`,
    component: CustomTimecardListPage,
    exact: true,
    private: true,
    name: "timecards.timecard.list",
  },
  {
    path: `/timecard/custom/list/:id/daily`,
    component: CustomDailyTimecardListPage,
    exact: true,
    private: true,
    name: "timecards.timecard.daily_timecard.list",
  },
  {
    path: `/timecard/custom/list/:id/dailyV0`,
    component: CustomDailyTimecardListPageV2,
    exact: true,
    private: true,
    name: "timecards.timecard.daily_timecard.list",
  },
  {
    path: `/timecard/custom/list/createtimecard`,
    component: CustomCreateTimeCardPage,
    exact: true,
    private: true,
    name: "timecards.custom.customcreatetimecard",
  },

  //////////////activity routes/////////////

  {
    path: `/activity/timecard/custom/list/:id/split`,
    component: CustomActivitySplitListPage,
    exact: true,
    private: true,
    name: "custom.activity.split",
  },
  {
    path: `/activity/timecard/list/:timecard/historic/:id/split`,
    component: ActivityHistoricSplitListPage,
    exact: true,
    private: true,
    name: "activity.historic.split",
  },
  {
    path: `/activity/timecard/custom/list/:id/split/add`,
    component: CustomActivitySplitForm,
    exact: true,
    private: true,
    name: "custom.split.add",
  },
  {
    path: `/activity/timecard/list/:id/add-session`,
    component: ManualSessionPage,
    exact: true,
    private: true,
    name: "activity.manual",
  },
  {
    path: `/activity/tracking/current`,
    component: TrackCurrentListPage,
    exact: true,
    private: true,
    name: "activity.tracking.current",
  },
  {
    path: `/activity/tracking/historic`,
    component: TrackHistoricListPage,
    exact: true,
    private: true,
    name: "activity.tracking.historic",
  },

  ////////////////report routes/////////////

  {
    path: `/report`,
    component: ReportDashboardPage,
    exact: true,
    private: true,
    name: "report.dashboard",
  },
  {
    path: `/report/:id/:code/detail`,
    component: ReportDetailPage,
    exact: true,
    private: true,
    name: "report.detail",
  },
  {
    path: `/report/:id/:code/detail/:detailId`,
    component: ReportDataDetailPage,
    exact: true,
    private: true,
    name: "report.data_detail",
  },

  //////////////geofence routes////////////

  {
    path: `/geofence/job/lists`,
    component: GeoFenceListPage,
    exact: true,
    private: true,
    name: "geofence.list",
  },
  {
    path: `/geofence/job/lists/add`,
    component: GeoFenceFormPage,
    exact: true,
    private: true,
    name: "geofence.add",
  },
  {
    path: `/geofence/:id/job/lists/edit`,
    component: GeoFenceFormPage,
    exact: true,
    private: true,
    name: "geofence.edit",
  },

  //////////////////quality assuarance/////////////////

  {
    path: "/quality-assurance",
    component: QualityAssuranceListPageWrapper,
    exact: true,
    private: true,
    name: "knowledge_base.list",
  },

  {
    path: "/quality-assurance/list",
    component: QualityAssuranceListPage,
    exact: true,
    private: true,
    name: "knowledge_base.list",
  },
  //////////////////SMART CONNECT//////////////////////
  {
    path: "/smart_connector",
    component: SmartConnectListPageWrapper,
    exact: true,
    private: true,
    name: "knowledge_base.list",
  },

  {
    path: "/smart_connector/list",
    component: SmartConnectListPage,
    exact: true,
    private: true,
    name: "knowledge_base.list",
  },

  //////////////////job schedule routes////////////////

  {
    path: `/job/list`,
    component: JobListPage,
    exact: true,
    private: true,
    name: "job.list",
  },
  {
    path: `/job/list/add`,
    component: JobFormPage,
    exact: true,
    private: true,
    name: "job.add",
  },
  {
    path: `/job/list/:id/edit`,
    component: EditJobFormPage,
    exact: true,
    private: true,
    name: "job.edit",
  },
  {
    path: `/job/lists/task/`,
    component: JobTaskMappingListPage,
    exact: true,
    private: true,
    name: "job.task.assign",
  },
  {
    path: `/job/list/:id/geofence/add`,
    component: JobGeofencePage,
    exact: true,
    private: true,
    name: "job.add",
  },
  {
    path: `/job/list/:id/documents/edit`,
    component: JobDocumentsPage,
    exact: true,
    private: true,
    name: "job.edit",
  },
  {
    path: `/job/list/:id/task/edit`,
    component: JobTaskEditPage,
    exact: true,
    private: true,
    name: "job.edit",
  },
  {
    path: `/job/list/:id/employee/edit`,
    component: JobEmployeeEditPage,
    exact: true,
    private: true,
    name: "job.employee.edit",
  },
  {
    path: `/job/lists/jobreports`,
    component: JobReportsPage,
    exact: true,
    private: true,
    name: "job.reports",
  },
  {
    path: `/job/list/:id/budget/edit`,
    component: JobBudgetPage,
    exact: true,
    private: true,
    name: "job.edit",
  },
  {
    path: `/job/task/bulk-upload`,
    component: TaskBulkUploadPage,
    exact: true,
    private: true,
    name: "customer.bulkupload",
  },
  {
    path: `/job/list/bulk-upload`,
    component: JobBulkUploadPage,
    exact: true,
    private: true,
    name: "customer.bulkupload",
  },
  {
    path: `/job/list/new`,
    component: ManageJobsPage,
    exact: true,
    private: true,
    name: "job.list",
  },
  //////////////////business admin////////////////

  {
    path: `/business-admin/basic-setting`,
    component: BusinessAdminForm,
    exact: true,
    private: true,
    name: "business_admin.basic_setting",
  },

  //////////////////customer routes///////////////

  {
    path: `/customers/list`,
    component: CustomerListPage,
    exact: true,
    private: true,
    name: "customer.list",
  },
  {
    path: `/customers/list/:id/details`,
    component: CustomerFormPage,
    exact: true,
    private: true,
    name: "customer.details",
  },
  {
    path: `/customers/bulk-upload`,
    component: CustomerBulkUploadPage,
    exact: true,
    private: true,
    name: "customer.bulkupload",
  },

  ///////////////////knowledgebase routes////////////////
  {
    path: "/knowledge-base",
    component: KnowledgeBaseWrapper,
    exact: true,
    private: true,
    name: "knowledge_base.list",
  },
  {
    path: `/knowledge-base/list`,
    component: KnowledgeBaseListPage,
    exact: true,
    private: true,
    name: "knowledge_base.list",
  },
  {
    path: `/knowledge-base/add`,
    component: KnowledgeBaseFormPage,
    exact: true,
    private: true,
    name: "knowledge_base.add",
  },
  {
    path: `/knowledge-base/:id/edit`,
    component: KnowledgeBaseFormPage,
    exact: true,
    private: true,
    name: "knowledge_base.edit",
  },

  ///////////////////billing routes/////////////////
  {
    path: `/bill`,
    component: BillListWrapper,
    exact: true,
    private: true,
    name: "bill.list",
  },

  {
    path: `/bill/list`,
    component: AllBillsPage,
    exact: true,
    private: true,
    name: "bill.list",
  },
  {
    path: `/bill/:id/:tab`,
    component: BillFormPage,
    exact: true,
    private: true,
    name: "bill.edit",
  },

  ////////////////////////scheduling routes////////////////

  {
    path: `/scheduling/dashboard`,
    component: SchedulingDashboardPage,
    exact: true,
    private: true,
    name: "scheduling.dashboard",
  },
  {
    path: `/scheduling/shift-management`,
    component: ShiftManagementPage,
    exact: true,
    private: true,
    name: "scheduling.shift-management",
  },

  /////////////////////pto routes/////////////////////

  {
    path: `/pto-policy/list`,
    component: PtoPolicyPage,
    exact: true,
    private: true,
    name: "paid_time_off.policies",
  },
  {
    path: `/pto-policy/time-off/list`,
    component: TimeOffListPage,
    exact: true,
    private: true,
    name: "paid_time_off.list",
  },
  {
    path: `/pto-policy/bulk-upload`,
    component: BulkUploadPage,
    exact: true,
    name: "paid_time_off.bulkupload",
  },
  {
    path: `/pto-policy/add`,
    component: EditPtoPolicyPage,
    exact: true,
    name: "paid_time_off.addEditPolicies"
  },
  {
    path: `/pto-policy/:id/:tabId`,
    component: EditPtoPolicyPage,
    exact: true,
    name: "paid_time_off.addEditPolicies",
  },

  /////////////////////Notification routes////////////////////

  {
    path: `/communication/list`,
    component: NotificationList,
    exact: true,
    private: true,
    name: "communication.list",
  },

  ///////////////////communication center routes//////////////////

  {
    path: `/document/completion/report`,
    component: DocumentCompletionList,
    exact: true,
    private: true,
    name: "document.completion.report",
  },
  {
    path: `/document/:id`,
    component: DocuentSetupPage,
    exact: true,
    private: true,
    name: "documents.setup",
  },

  /////////////////////////learning center routes/////////////

  {
    path: `/learning/documents-completion/report`,
    component: DocumentCompletionList,
    exact: true,
    private: true,
    name: "learning.completion.report",
  },
  {
    path: `/learning/setup/:id`,
    component: LearningCenterSetupPage,
    exact: true,
    private: true,
    name: "learning.setup",
  },
  {
    path: `/learning/trainingForms/:id`,
    component: CreateAssessmentForm,
    exact: true,
    private: true,
    name: "documents.setup",
  },
  {
    path: `/learning/communication/list`,
    component: NotificationListPage,
    exact: true,
    private: true,
    name: "learning.communication.list",
  },

  ////////////////////form routes///////////////////

  {
    path: `/forms/fieldforms`,
    component: FieldFormsPage,
    exact: true,
    private: true,
    name: "forms.field",
  },
  {
    path: `/forms/:id/fieldform`,
    component: CreateFieldForm,
    exact: true,
    private: true,
    name: "forms.createfieldform",
  },
  {
    path: `/forms/:id/responces`,
    component: FormResponseList,
    exact: true,
    private: true,
    name: "forms.answerslist",
  },

  ///////////////////applicant routes//////////////////////

  {
    path: `/applicant/list`,
    component: ApplicantListPage,
    exact: true,
    private: true,
    name: "applicant.list",
  },
  {
    path: `/applicant/create`,
    component: AddApplicant,
    exact: true,
    private: true,
    name: "applicant.add",
  },
  {
    path: `/applicant/:id/edit/:tab`,
    component: AddApplicant,
    exact: true,
    private: true,
    name: "applicant.edit",
  },
];
export default routes;
